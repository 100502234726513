import GA4 from "react-ga4";
import { omit } from "lodash"; // https://github.com/codler/react-ga4#reactgaeventoptions

// https://github.com/codler/react-ga4#reactgaeventoptions
type TGoogleTagEventOptions = {
  category: "Site" | "ExtraFeatures";
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
};

// Should be removed after a few months...
// last update: 6Jun, 2023
export const g4log = (...args: any[]) => console.log("[🚀GA4]", ...args);

/**
 * Send custom event to Google Analytics
 * @param options
 * @param params
 */
export const trackEvent = (options: TGoogleTagEventOptions, params: Record<string, string | number> = {}) => {
  g4log("[⛳️trackEvent]", JSON.stringify(options));

  // eslint-disable-next-line camelcase
  GA4.event(options.action, {
    ...omit(options, "action", "category"),
    ...params,
    event_category: options.category,
  });
};
