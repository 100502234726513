"use client";

import GA4 from "react-ga4";
import { InitOptions } from "react-ga4/types/ga4";
import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { baseUrl } from "@/const";
import { g4log } from "@/helpers/gtag";

// https://www.npmjs.com/package/react-ga4
// https://github.com/react-ga/react-ga

const DOMAINS = [baseUrl, `app.${baseUrl}`];

const GoogleAnalytics4 = ({ trackingId, adsId }: { trackingId?: string; adsId?: string }) => {
  const pathname = usePathname();

  const isReady = trackingId && GA4.isInitialized;

  // Initialize
  useEffect(() => {
    if (!trackingId) return;

    /* eslint-disable camelcase */
    let products: InitOptions[] = [
      {
        trackingId,
        gtagOptions: {
          currency: "USD",
          linker: { domains: DOMAINS }, // cross-domain app
          // cookie_domain: cookieDomain,
          send_page_view: false,
          anonymize_ip: true,
        },
      },
    ];

    if (adsId) {
      products.push({
        trackingId: adsId,
        // gaOptions: { debug_mode: true },
        // gtagOptions: { debug_mode: true },
      });
    }

    setTimeout(() => {
      if (GA4.isInitialized) return;
      GA4.initialize(products);
      g4log("Initialized!", { products, page: document.title, domains: DOMAINS });
      // @ts-ignore
      (globalThis || window)._ga = GA4;
    }, 500);

    // save search params to cookies
    try {
      window.location.search
        .replace("?", "")
        .split("&")
        .map((i) => (document.cookie = `${i};`));
    } catch (e) {
      console.error("Set cookie from search params failed.");
    }
  }, [trackingId, adsId]);

  // [global tracker] "Pageview" (event) -> Disable default history change
  useEffect(() => {
    if (!isReady) return; // not initialized yet
    if (pathname === "/") return; // prevent duplicates of 1st pageview

    g4log("PageView", pathname);

    GA4.send({ hitType: "pageview", page: pathname, title: document.title });
  }, [isReady, pathname]);

  return null;
};

export default GoogleAnalytics4;
